import axios from 'axios';
import {apiUrl} from '../config';

class WkAPI {

  constructor() {
    this.setInstance();
  }

  setInstance() {
    this.api =  axios.create({
      baseURL: apiUrl,
      headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    });
  }

  setToken() {
    this.setInstance();
  }

  getReport(from, to) {
    return  this.api.get('/reports', {params: {
      from: from,
      to: to
    }})
  }
  getTrafficReport(from, to) {
    return  this.api.get('/reports/ga', {params: {
      from: from,
      to: to
    }})
  }

  getProfile() {
    return this.api.get('/profile')
  }

  getOrders() {
    return this.api.get('/orders');
  }

  getShipments(returns = false) {
    return this.api.get('/shipments?returns='+returns);
  }

  createShipment(shipment) {
    return this.api.post('/shipments', shipment)
  }

  createBulkShipments(bulkShipments) {
    return this.api.post('/bulk-shipments', bulkShipments)
  }

  createReturn(shipment) {
    return this.api.post('/returns', shipment)
  }

  printShipment(id) {
    return this.api.post(`/shipments/${id}/print`, {})
  }
}
const api = new WkAPI();
export default api;