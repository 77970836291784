import React from 'react';
import axios from 'axios';
import history from '../history';
import {apiUrl} from '../config';
import api from '../apis/wunderklein-api';
import { Form, Icon, Input, Button } from 'antd';

const login = (email, password) => {
    axios.post(`${apiUrl}/authentication`, {email, password})
    .then(response => {
        localStorage.setItem('token', response.data.token);
        api.setToken();
        history.push('/');
    })
    .catch(error => console.log(error));
}

const loginPage = (props) => {
    
    const { getFieldDecorator } = props.form;

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
          if (!err) {
            login(values.email, values.password);
          }
        });
      };

    return (
        <div style={{width: '30%', background: '#fff', marginLeft: 'auto', marginRight: 'auto', padding: 24, textAlign: 'center'}}>
            <img style={{display: 'inline', marginBottom: 20}} alt="Wunderklein Logo" src="/logo_slogan_new.png"  width="300"/>
            <h1>Wunderklein E-Commerce Portal</h1>

    <Form onSubmit={handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Passwort"
            />,
          )}
        </Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
            Log in
          </Button>
      </Form>
        </div>
    )
} 

export default Form.create({ name: 'normal_login' })(loginPage);