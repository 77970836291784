import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import {Link} from 'react-router-dom';
const { Header, Content} = Layout;

function SidebarLayout(props) {
  
  let selectedKey = props.path === '/shipments' ? '3' : '1';

  return (
   <Layout className="layout">
  <Header>
    <Menu
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={[selectedKey]}
      style={{ lineHeight: '64px' }}
    >
      <Menu.Item key="1"><Link to="/"><Icon type="shop" /> Dashboard</Link></Menu.Item>
      <Menu.Item key="2"><Link to="/orders"><Icon type="inbox" /> Bestellungen</Link></Menu.Item>
      <Menu.Item key="3"><Link to="/shipments"><Icon type="barcode" /> Versand</Link></Menu.Item>
      <Menu.Item key="4"><Link to="/customers"><Icon type="team" /> Kunden</Link></Menu.Item>
      <Menu.Item key="5"><Link to="/settings"><Icon type="setting" /> Einstellungen</Link></Menu.Item>
     
    </Menu>
  </Header>
  <Content style={{ padding: '0 50px' }}>
    <div style={{minHeight: '100vh' }}> {props.children} </div>
  </Content>
</Layout>
  );
}

export default SidebarLayout;
