import React from 'react';
import { Form, Input, Button, Select } from 'antd';
const { Option } = Select;

class CreateShipmentForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props._onSubmit(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label="Land" hasFeedback>
          {getFieldDecorator('country', {
            rules: [{ required: true, message: 'Bitte Land auswählen' }],
          })(
            <Select placeholder="Land">
              <Option value="AT">Österreich</Option>
              <Option value="DE">Deutschland</Option>
            </Select>,
          )}
        </Form.Item>
        <Form.Item  label="Name">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Bitte Namen angegeben',
              },
            ],
          })(<Input placeholder="Name" />)}
        </Form.Item>
        <Form.Item  label="Straße">
          {getFieldDecorator('street', {
            rules: [
              {
                required: true,
                message: 'Bitte Straße angeben',
              },
            ],
          })(<Input placeholder="Straße" />)}
        </Form.Item>
        <Form.Item  label="Hausnummer">
          {getFieldDecorator('houseNumber', {
            rules: [
              {
                required: true,
                message: 'Bitte Hausnummer angeben',
              },
            ],
          })(<Input placeholder="Hausnr." />)}
        </Form.Item>
        <Form.Item  label="PLZ">
          {getFieldDecorator('zipCode', {
            rules: [
              {
                required: true,
                message: 'Bitte PLZ angeben',
              },
            ],
          })(<Input placeholder="PLZ" />)}
        </Form.Item>
        
        <Form.Item  label="Stadt">
          {getFieldDecorator('city', {
            rules: [
              {
                required: true,
                message: 'Please input your name',
              },
            ],
          })(<Input placeholder="Stadt" />)}
        </Form.Item>
        <Button type="primary" htmlType="submit">
           Erstellen
        </Button>
        <Button onClick={this.props._onAbort}>
           Abbrechen
        </Button>
      </Form>
    );
  }
}

export default Form.create({ name: 'create_shipment' })(CreateShipmentForm);