import React from 'react';
import {Table} from 'antd';

const columns = [
    {
        dataIndex: 'name',
        title: 'Name',
        render: (text, record) => text + ' ' + record.variant
    },
    {
        dataIndex: 'count',
        title: 'Anzahl',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.count - b.count,
    },
    {
        dataIndex: 'cost',
        title: 'Produktkosten',
        render: text => text + ' €'
    }
]

const topProductsTable = (props) => {

    let dataSource = [];
    props.products.forEach(product => {
        dataSource.push({
            key: product.variant_id,
            name: product.product,
            count: product.count,
            cost: product.total_cost,
            variant: product.variant
        })
    })

    return <Table columns={columns} dataSource={dataSource} />
}

export default topProductsTable;