import React from 'react';
import { Spin } from 'antd';

class Loader extends React.Component {
    render() {
        return (
            <div className="loader">
            <img alt="Spinning icon which indicates loading" className="logo" src="logo_slogan_new.png" />
            <br/>
            <Spin />
          </div>
        )
    }
}

export default Loader;