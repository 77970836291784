import React from 'react';
import OrderDetailContainer from '../containers/orderDetailContainer';

function OrderDetail() {

  return (  
    <OrderDetailContainer />
  );
    
}

export default OrderDetail;