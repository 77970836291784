import React, {Component} from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { Router } from 'react-router-dom';
import SidebarLayout from './layouts/sidebarLayout';
import EmptyLayout from './layouts/emptyLayout';
import history from './history';
import API from './apis/wunderklein-api';
import Dashboard from './pages/dashboard';
import Shipments from './pages/shipments';
import Orders from './pages/orders';
import OrderDetail from './pages/orderDetail';
import LoginPage from './pages/login';

import Loader from './components/loader';

const AppRoute = ({layout,component,...props}) => {
     return (
        <Route
        {...props}
        render={(renderProps) => {
            if(props.protected && localStorage.getItem('token') === null) {
                return <Redirect to="/login" />
            }
            else {
                return React.createElement(layout, props, React.createElement(component, {...props})) 
            }
            
        }}/>
      );
};


class AppRouter extends Component {

    state = {loading: true, user: {}};

    componentDidMount() {
        API.getProfile()
        .then(response => this.setState({loading: false, user: response.data}))
        .catch(error => {
            if(error.response) {
                if(error.response.status === 401) {
                    this.setState({loading: false});
                    localStorage.removeItem('token');
                    history.push('/login');
                }
            }
        });
    }

    render() {
        let loader = "";

        if(this.state.loading) {
            loader = <Loader />;
        }
        return (
            <Router history={history}>
            {loader}
            {!this.state.loading && (
                <Switch>
                <AppRoute protected {...this.props} layout={SidebarLayout} exact path="/" component={Dashboard}/>
                <AppRoute protected {...this.props} layout={SidebarLayout} exact path="/shipments" component={Shipments}/>
                <AppRoute protected {...this.props} layout={SidebarLayout} exact path="/orders" component={Orders}/>
                <AppRoute protected {...this.props} layout={SidebarLayout} exact path="/orders/:id" component={OrderDetail}/>
                <AppRoute {...this.props} layout={EmptyLayout} exact path="/login" component={LoginPage}/>
            </Switch>
            )}
            
          </Router>
        )
    }
}

export default AppRouter;