import React from 'react';
import {Table, Tag} from 'antd';
import {Link} from 'react-router-dom';
import moment from 'moment';
import api from '../apis/wunderklein-api';

const columns = [
    {
        title: 'Bestellnummer',
        dataIndex: 'order_number',
        render: (text, item) => <Link to={`/orders/${item.key}`}>{'#' + text}</Link>
      },
      {
        title: 'Datum',
        dataIndex: 'created_at',
      },
  {
    title: 'Kunde',
    dataIndex: 'name'
  },
  
  {
    title: 'Status',
    dataIndex: 'fullfilment_status',
    render: text =>  <Tag color="geekblue">{text}</Tag>
  },
  {
    title: 'Zahlung',
    dataIndex: 'financial_status',
    render: text =>  <Tag color="geekblue">{text}</Tag>
  },
  {
    title: 'Gesamt',
    dataIndex: 'price',
    render: text => text + '€'
  },
];


class ShipmentsContainer extends React.Component {

    state = {data: [], loading: true};

    fetchOrders() {
      api.getOrders()
      .then(response => {
          this.setState({data: response.data, loading: false});
      }).catch((err, test) => console.log(err));
    }

    componentDidMount() {
      this.fetchOrders();
    }

    render() {
      
      let orderData = [];
      

      
      
      this.state.data.forEach(item => {
        let fullfillmentStatus = 'Offen';
        
        orderData.push({
          key: item.id,
          order_number: item.order_number,
          created_at: moment(item.created_at).format('DD.MM.YYYY'),
          name: item.shipping_address.first_name + ' ' + item.shipping_address.last_name,
          address: item.shipping_address.address1,
          address1: item.shipping_address.address1,
          city: item.shipping_address.city,
          zipCode: item.shipping_address.zip,
          line_items: item.line_items,
          fullfilment_status: fullfillmentStatus,
          financial_status: item.financial_status,
          price: item.total_price

        });
      })
    
        return (
            <Table pagination={{ pageSize: 100 }} columns={columns} dataSource={orderData} />
        )
    }
}

export default ShipmentsContainer;