import React from 'react';
import { Layout} from 'antd';

const { Content, Footer } = Layout;

const emptyLayout = (props) => {
    return (
        <div>
            <Layout className="layout">
    <Content style={{ padding: '0 50px' }}>
      
      <div style={{padding: 24, minHeight: 280 }}>{props.children}</div>
    </Content>
    <Footer style={{ textAlign: 'center' }}>WUNDERklein. ©2019 Created by WUNDERklein. E-Commerce Solutions</Footer>
  </Layout>,
            
        </div>
    )
}

export default emptyLayout;