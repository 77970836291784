import React from 'react';
import API from '../apis/wunderklein-api';
import {Row, Col, Card, Statistic, DatePicker, List} from 'antd';
import ProductTable from '../components/topProductsTable';
import RevenueChart from '../components/revenueChart';
import moment from 'moment';

const { RangePicker } = DatePicker;

class Dashboard extends React.Component {

  state = {report: {products: [], trafficReport: {}}, loading: true, trafficLoading: true, trafficReport: {}};

  onDateChange(dates, dateStrings) {
    console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
    this.fetchReport(dateStrings[0], dateStrings[1])
  }

  fetchReport(from, to) {
    this.setState({loading: true});
    API.getReport(from, to)
    .then(response => this.setState({report: response.data, loading: false}))
    .catch(error => console.log(error));
    API.getTrafficReport(from,to)
    .then(response => this.setState({trafficReport: response.data, trafficLoading: false}))
    .catch(error => console.log(error));
  }

  componentDidMount() {
    this.fetchReport(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
  }

  render() {
    let {transactionFeesDirectbanking, transactionFeesKlarna, transactionFeesPaypal, transactionFeesStripe} = this.state.report;
    return  (
      <div>
          <Row style={{marginBottom: 20}}>
            <div style={{float: 'right'}}>
                <RangePicker
                defaultValue={[moment(), moment()]}
            ranges={{
              'Heute': [moment(), moment()],
              'Dieser Monat': [moment().startOf('month'), moment().endOf('month')],
            }}
            onChange={this.onDateChange.bind(this)}
    />
    </div>
          </Row>
          <Row gutter={16} style={{marginBottom: 20}}>
          <Col className="gutter-row" span={6}>
            <Card loading={this.state.loading}><Statistic title="Umsatz" value={this.state.report.revenue} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€"/></Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card loading={this.state.loading}><Statistic title="Netto Marge" value={(this.state.report.netProfit/this.state.report.revenue)*100} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="%" /></Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card loading={this.state.loading}><Statistic title="Bestellungen" value={this.state.report.numberOfOrders} /></Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card loading={this.state.loading}><Statistic title="Brutto Gewinn" value={this.state.report.grossProfit} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€" /></Card>
          </Col>
        </Row>
        <Row gutter={16} style={{marginBottom: 20}}>
          <Col className="gutter-row" span={6}>
            <Card loading={this.state.loading}><Statistic title="Versandkosten" value={this.state.report.shippingCost} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€"/></Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card loading={this.state.loading}><Statistic title="Produktkosten" value={this.state.report.cogs} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€" /></Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card loading={this.state.loading}><Statistic title="Transaktionsgebühren" value={transactionFeesDirectbanking + transactionFeesKlarna + transactionFeesPaypal + transactionFeesStripe} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€" /></Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card loading={this.state.loading}><Statistic title="Umsatzsteuer" value={this.state.report.tax} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€" /></Card>
          </Col>
        </Row>
        <Row gutter={16} style={{marginBottom: 20}}>
          <Col className="gutter-row" span={6}>
            <Card loading={this.state.loading}><Statistic title="Rückerstattungen" value={this.state.report.refunds} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€"/></Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card loading={this.state.loading}><Statistic title="Rücksendungen" value={this.state.report.returnShipments} /></Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card loading={this.state.loading}><Statistic title="Kosten Rücksendungen" value={this.state.report.returnShipments*1.5} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€"/></Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card loading={this.state.loading}><Statistic title="Brutto Marge" value={(this.state.report.grossProfit/this.state.report.revenue)*100} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="%"/></Card>
          </Col>
        </Row>
        <Row gutter={16} style={{marginBottom: 20}}>
          <Col className="gutter-row" span={6}>
            <Card loading={this.state.loading}><Statistic title="Google Ads Kosten" value={this.state.report.googleAdSpend} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€"/></Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card loading={this.state.loading}><Statistic title="Facebook Ads Kosten" value={this.state.report.facebookSpend} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€"/></Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card loading={this.state.loading}><Statistic valueStyle={{ color: '#3f8600' }} title="Netto Gewinn" value={this.state.report.netProfit} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€" /></Card>
          </Col>
          <Col className="gutter-row" span={6}>
          <Card loading={this.state.loading}><Statistic title="Durchsch. Bestellwert" value={this.state.report.revenue/this.state.report.numberOfOrders} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€" /></Card>
          </Col>
        </Row>
        <Row gutter={16} style={{marginBottom: 20}}>
          <Col className="gutter-row" span={6}>
            <Card loading={this.state.loading}><Statistic title="Nutzer" value={this.state.report.trafficReport.total} precision={0} decimalSeparator={","} groupSeparator={"."}/></Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card loading={this.state.loading}><Statistic title="Conversion Rate" value={(this.state.report.numberOfOrders/this.state.report.trafficReport.total)*100} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="%"/></Card>
          </Col>
        </Row>

        <Row style={{marginBottom: 20}}>
            <Col span={24}>
              <Card loading={this.state.trafficLoading} style={{minHeight: 200}} title="Umsatz pro Kanal"> 
              <Row>
                <Col span={6}>
                <List
            dataSource={this.state.trafficReport.byChannel}
            renderItem={item => (
              <List.Item key={item.channel}>
                <List.Item.Meta
                  title={<a href="https://ant.design">{item.channel}</a>}
                />
                <div><Statistic value={item.revenue} precision={2} decimalSeparator={","} groupSeparator={"."} suffix="€" /></div>
              </List.Item>
            )}
          ></List>
                </Col>
                <Col span={18}>
                <div style={{height: 500}}>
              <RevenueChart report={this.state.trafficReport}/>
              </div>
                </Col>
              </Row>
             
              
              </Card>
              
            </Col>
        </Row> 

        <Row style={{marginBottom: 20}}>
            <Col span={24}>
              <Card loading={this.state.loading} style={{minHeight: 200}} title="Nutzer pro Kanal"> 
              <Row>
                <Col span={6}>
                <List
            dataSource={this.state.report.trafficReport.byChannel}
            renderItem={item => (
              <List.Item key={item.channel}>
                <List.Item.Meta
                  title={<a href="https://ant.design">{item.channel}</a>}
                />
                <div><Statistic value={item.users} precision={0} decimalSeparator={","} groupSeparator={"."} /></div>
              </List.Item>
            )}
          ></List>
                </Col>
                <Col span={18}>
                <div style={{height: 500}}>
              <RevenueChart report={this.state.report.trafficReport}/>
              </div>
                </Col>
              </Row>
             
              
              </Card>
              
            </Col>
        </Row> 
     

          

        <Row>
          <Col span={24}>

            <Card loading={this.state.loading} title="Top Produkte">
              <ProductTable products={this.state.report.products} />
            </Card>
          </Col>
        </Row>
      </div>
  )
  }
  
}

export default Dashboard;