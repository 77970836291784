import React from 'react';
import { ResponsiveBar } from '@nivo/bar'


const MyResponsiveBar = (props) => (
    <ResponsiveBar
        data={props.report.series}
        indexBy="date"
        keys={props.report.keys}
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        colors={{ scheme: 'spectral' }}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        enableLabel={false}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
);

export default MyResponsiveBar;