import React from 'react';
import {Card} from 'antd';
import OrdersContainer from '../containers/ordersContainer';

function Orders() {

  return (  
    <Card>
      <OrdersContainer />
    </Card>
  );
    
}

export default Orders;