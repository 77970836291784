import React from 'react';
import {Card} from 'antd';
import ShipmentsContainer from '../containers/shipmentsContainer';

function Shipments() {

  return (  
    <Card>
      <ShipmentsContainer />
    </Card>
  );
    
}

export default Shipments;