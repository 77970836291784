import React from 'react';
import {Table, Tabs, Button, Tag, Modal, Spin, Icon, List, message} from 'antd';
import moment from 'moment';
import ShipmentForm from '../components/createShipmentForm';
import ReturnForm from '../components/returnForm';
import api from '../apis/wunderklein-api';

const { TabPane } = Tabs;
const antIcon = <Icon type="loading" style={{ fontSize: '500%' }} spin />;

const columns = [
  {
    title: 'Bestellnummer',
    dataIndex: 'order_number',
    render: text => '#' + text,
    key: 'order_number'
  },
  {
    title: 'Kunde',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Datum',
    dataIndex: 'created_at',
    key: 'created_at'
  },
  {
    title: 'Zahlung',
    dataIndex: 'financial_status',
    key: 'financial_status',
    render: text => <Tag color="green">{text}</Tag>
  },
  {
    title: 'Gesamt',
    dataIndex: 'total_price',
    key: 'total_price',
    render: text => text + '€',
  },

];

const shipmentColumns = [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Datum',
    dataIndex: 'created',
    render: date => {
      return moment(date).format('DD.MM.YYYY');
    }
  },
  {
    title: 'Bestellnummer',
    dataIndex: 'orderNumber',
    render: text => '#' + text
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: text => {
      return <Tag color="blue">{text}</Tag>;
    }
  },
  {
    title: 'Tracking',
    dataIndex: 'trackingNumber',
  },
  {
    title: 'Land',
    dataIndex: 'country',
  },
  {
    title: 'PLZ',
    dataIndex: 'zipCode',
  },
  {
    title: 'Stadt',
    dataIndex: 'city',
  },
  {
    title: 'Adresse',
    dataIndex: 'address',
  }
];

const joinPropertiesToText = (properties) => {
  let text = '';
  properties.forEach(property => {    
    if(property.value !== '' && !property.name.includes('_bold')) {
      text += ' ' + property.name + ': ' + property.value;
    }
  });
  return text;
}

const renderItems = (items) => {
  return (
    <List
    dataSource={items}
    renderItem={item => (
      <List.Item>
         <List.Item.Meta
         
          title={`${item.quantity} x ${item.name}`}
          description={joinPropertiesToText(item.properties)}
        />
      </List.Item>
    )}
    />
  );
}

class ShipmentsContainer extends React.Component {

    state = {data: [], loading: true, selectedOrders: [], shipments: [], returnShipments: [], printing: false, selectedShipments: [], newShipmentModalVisible: false, returnModalVisible: false};

    fetchOrders() {
      api.getOrders()
      .then(response => {
          this.setState({data: response.data, loading: false});
      }).catch((err, test) => console.log(err));
    }

    fetchShipments() {
      api.getShipments()
      .then(response => {
        this.setState({shipments: response.data});
      });
    }
    fetchReturnShipments() {
      api.getShipments(true)
      .then(response => {
        this.setState({returnShipments: response.data});
      });
    }

    showModal = () => {
      this.setState({
        newShipmentModalVisible: true,
      });
    };

    showReturnModal = () => {
      this.setState({
        returnModalVisible: true,
      });
    };
  
    handleOk = e => {
      this.setState({
        newShipmentModalVisible: false,
        returnModalVisible: false
      });
    };
  
    handleCancel = e => {
      this.setState({
        newShipmentModalVisible: false,
        returnModalVisible: false
      });
    };

    componentDidMount() {
      this.fetchOrders();
      this.fetchShipments();
      this.fetchReturnShipments();
    }

    printShipments() {
      console.log(this.state.selectedShipments);
      // let requests = [];
      // this.state.selectedShipments.forEach(shipment => {
      //   requests.push(api.printShipment(shipment._id));
      // })
      // Promise.all(requests).then(() => {
      //   this.setState({selectedShipments: []});
      // });
    }

    createManualShipment(shipmentData) {
      
      this.setState({printing: true, newShipmentModalVisible: false});
      let street = shipmentData.street + ' ' + shipmentData.houseNumber;
      street = street.trim();

      let shipment = {
        address: {
          name: shipmentData.name,
          street1: street,
          street2: '',
          country: shipmentData.country,
          zipCode: shipmentData.zipCode,
          city: shipmentData.city
        },
        weight: '1'
      }

      api.createShipment(shipment)
      .then(response => {
        this.setState({printing: false})
        this.fetchShipments();
      })
      .catch(error => {
        console.log(error.response);
        this.setState({printing: false, newShipmentModalVisible: true});
        message.error("Fehler beim Anlegen des Auftrages! Adresse prüfen!")
      });
    }

    createReturn(shipmentData) {
      
      this.setState({printing: true, returnModalVisible: false});
      let street = shipmentData.street + ' ' + shipmentData.houseNumber;
      street = street.trim();

      let shipment = {
        address: {
          name: shipmentData.name,
          street1: street,
          street2: '',
          country: shipmentData.country,
          zipCode: shipmentData.zipCode,
          city: shipmentData.city
        },
        weight: '1'
      }

      api.createReturn(shipment)
        .then(response => {
          this.setState({printing: false});
          this.downloadPDF(response.data.label, "return-label");
        })
        .catch(error => {
          console.log(error);
          this.setState({printing: false, returnModalVisible: true});
          message.error("Fehler beim Anlegen des Auftrages! Adresse prüfen!")
        });
    }

    downloadPDF(pdf, fileName) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      
      downloadLink.href = linkSource;
      downloadLink.download = fileName + ".pdf";
      downloadLink.click();
    }
    
    createShipments() {
      
      let shipments = [];
      this.setState({printing: true});
      this.state.selectedOrders.forEach(order => {
        
        let shipment = {
          address: {
            name: order.name,
            street1: order.address1,
            street2: '',
            country: order.countryCode,
            zipCode: order.zipCode,
            city: order.city
          },
          weight: '1',
          reference: order.order_number
        }
        shipments.push(shipment);
      })

      api.createBulkShipments({shipments})
      .then(response => {
        this.setState({printing: false});
        this.downloadPDF(response.data.content, "bulk-labels");
      })
      .catch(error => {
        console.log(error);
        this.setState({printing: false});
        message.error("Fehler!")
      });
    
    }

    render() {

      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          this.setState({selectedOrders: selectedRows});
        }
      };

      const rowSelectionShipments = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(selectedRows);
          this.setState({selectedShipments: selectedRows});
        }
      };

      let tableData = [];
      let expandedRowKeys = [];

      this.state.data.forEach(item => {
        let country = '🇩🇪';
        if(item.shipping_address.country_code === 'AT') {
          country = '🇦🇹';
        }
        expandedRowKeys.push(item.id);
        tableData.push({
          key: item.id,
          order_number: item.order_number,
          created_at: moment(item.created_at).format('DD.MM.YYYY'),
          name: item.shipping_address.first_name + ' ' + item.shipping_address.last_name,
          address: item.shipping_address.address1,
          country: country + ' ' + item.shipping_address.country_code,
          address1: item.shipping_address.address1,
          countryCode: item.shipping_address.country_code,
          city: item.shipping_address.city,
          zipCode: item.shipping_address.zip,
          line_items: item.line_items,
          total_price: item.total_price,
          financial_status: item.financial_status

        });
      })
      
      let shipmentData = [];
      let returnShipmentData = [];
     
      this.state.shipments.forEach(shipment => {
        shipment.key = shipment._id;
        shipmentData.push(shipment);
      })
      this.state.returnShipments.forEach(shipment => {
        shipment.key = shipment._id;
        returnShipmentData.push(shipment);
      })
      

      let labelCount = null;

      if(this.state.selectedOrders.length > 0) {
        labelCount = <span>{this.state.selectedOrders.length}</span>;
      }

        return (
          <Tabs type="card">
          <TabPane tab="Eingehende Aufträge" key="1">
          <Modal bodyStyle={{minHeight: 300}} closable={false} footer={null} visible={this.state.printing}>
            <div style={{textAlign: 'center'}}>
              <Icon theme="twoTone" type="printer" style={{fontSize: '1000%', display: 'block', marginBottom: 40}} />
            <Spin size="large" indicator={antIcon} />
            </div>
          
          </Modal>
          <div style={{marginBottom: 20}}>
            <Button disabled={this.state.selectedOrders.length === 0} onClick={this.createShipments.bind(this)} type="primary" size="large">{labelCount}&nbsp;Labels erstellen</Button>
            <Button size="large" style={{marginLeft: 15}} type="primary" onClick={this.showModal}>
         <Icon type="plus" /> Neue Sendung
        </Button>
        <Button onClick={this.showReturnModal} size="large" style={{marginLeft: 15}} type="primary"><Icon type="plus" /> Neue Rücksendung</Button>
        <Modal
          footer={null}
          title="Neuen Versandauftrag anlegen"
          visible={this.state.newShipmentModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
         <ShipmentForm _onSubmit={this.createManualShipment.bind(this)} _onAbort={() => this.handleCancel()} />
        </Modal>
        <Modal
          footer={null}
          title="Neue Retoure anlegen"
          visible={this.state.returnModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
         <ReturnForm _onSubmit={this.createReturn.bind(this)} _onAbort={() => this.handleCancel()} />
        </Modal>
          </div>
            <Table size="middle" expandedRowKeys={expandedRowKeys} defaultExpandAllRows={true} expandedRowRender={record => renderItems(record.line_items)} pagination={{defaultPageSize: 20}} loading={this.state.loading} rowSelection={rowSelection} columns={columns} dataSource={tableData} />
          </TabPane>
          <TabPane tab="Erstellte Sendungen" key="2">
          <div style={{marginBottom: 20}}>
            <Button onClick={this.printShipments.bind(this)} type="primary" disabled={this.state.selectedShipments.length === 0} size="large">Drucken</Button>
          </div>
          <Table rowSelection={rowSelectionShipments} columns={shipmentColumns} dataSource={shipmentData} />
          </TabPane>
          <TabPane tab="Erstellte Rücksendungen" key="3">
          <Table columns={shipmentColumns} dataSource={returnShipmentData} />
          </TabPane>
        </Tabs>
        )
    }
}

export default ShipmentsContainer;